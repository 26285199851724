import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Reports Admin", "has-active": false, "has-filter-inactive": false, "actions-width": 120, "create-button-text": "Add New" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Top Level Menu")]), _c("div", { staticClass: "admin-menu-filter mr-2" }, [_c("select-filter", { attrs: { "reference": "web-analytics.common.admin-menu-reports", "source": "menuId", "source-label": "text", "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "menuId");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mx-1", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1), record.reportId !== 0 && record.reportUrl ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("View Report")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onClickViewReport(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("url-field", { key: "reportName", attrs: { "data-index": "reportName", "title": "Name", "base-url": "", "clickable": false, "sorter": true } }), _c("text-field", { key: "reportDescription", attrs: { "data-index": "reportDescription", "title": "Description", "sorter": true } }), _c("text-field", { key: "reportUrl", attrs: { "data-index": "reportUrl", "title": "Report Url", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var ReportsAdmin_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".admin-menu-filter[data-v-1147b9df]{width:150px}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ReportsAdmin",
  mixins: [MyUtil],
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1
    };
  },
  computed: {
    menuId() {
      let value = this.crud.getQueryString("menuId");
      return value ? value.value : "";
    }
  },
  methods: {
    onClickViewReport(record) {
      window.open(`${record.navigateUrl}`, "_blank");
    },
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Report?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Report?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.delete(`${this.apiUrl}/web-analytics/admin-reports`, {
          data: { menuId: this.menuId, reportId: record.reportId }
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Please contact your system administrator with details of how the error occurred."
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1147b9df", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportsAdmin = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.admin-menu-reports" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.admin-reports", "resource-id-name": "reportId", "create-route": "/web-analytics/reports-admin/create", "edit-route": "/web-analytics/reports-admin/:id" } }, [_c("reports-admin")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ReportsAdmin
  },
  data() {
    return {
      apiUrl,
      ReportsAdmin,
      itemsMenu: [
        {
          key: "admin",
          title: "Admin",
          path: ""
        },
        {
          key: "reports-admin",
          title: "Reports Admin",
          path: "/web-analytics/reports-admin"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
